import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrvServicesModule, OrvMessageService } from 'orv-ui-base-lib';

const NB_CORE_PROVIDERS = [
  ...OrvServicesModule.forRoot().providers,
  OrvMessageService
];

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [],
  declarations: [],
})
export class CoreModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    } as ModuleWithProviders;
  }
}
