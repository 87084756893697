import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollSpyModule } from 'ngx-scrollspy';
import { TrialModalComponent } from '../components/trial-modal/trial-modal.component';
import { TosModalComponent } from '../components/tos-modal/tos-modal.component';
import { AboutUsModalComponent } from '../components/about-us-modal/about-us-modal.component';
import { FaqModalComponent } from '../components/faq-modal/faq-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivacyPolicyModalComponent } from '../components/privacy-policy-modal/privacy-policy-modal.component';
import { EulaModalComponent } from '../components/eula-modal/eula-modal.component';
import { CookiePolicyModalComponent } from '../components/cookie-policy-modal/cookie-policy-modal.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    TrialModalComponent,
    TosModalComponent,
    PrivacyPolicyModalComponent,
    EulaModalComponent,
    CookiePolicyModalComponent,
    AboutUsModalComponent,
    FaqModalComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    NgbModule,
    ScrollSpyModule.forRoot(),
    ScrollToModule.forRoot()
  ],
  entryComponents: [
    TrialModalComponent,
    PrivacyPolicyModalComponent,
    EulaModalComponent,
    CookiePolicyModalComponent,
    TosModalComponent,
    AboutUsModalComponent,
    FaqModalComponent
  ]
})
export class LayoutModule { }
