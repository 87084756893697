import { Component, Injectable, AfterViewInit } from '@angular/core';
import { ScrollSpyService } from 'ngx-scrollspy';
import { Router } from '@angular/router';
import { TrialModalComponent } from 'src/app/components/trial-modal/trial-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements AfterViewInit {
  totalOffset: number = 40;
  sections = {};


  constructor(
    private scrollSpyService: ScrollSpyService,
    private router: Router,
    private modalService: NgbModal
    ) { }

  ngOnInit() {



    window.onscroll = function () { myFunction() };
    var logo = document.getElementById("logo");
    
    logo.style.display = 'inline-block';
    logo.style.backgroundImage = "url(logo-onroute-white.svg)";
    logo.style.backgroundRepeat  = "no-repeat";
    logo.style.backgroundSize = "contain";
    logo.style.backgroundPosition = "center";

    var navbar1 = document.getElementById("navbar1");
    var navbarLinks = document.getElementById("navbar-links").getElementsByTagName("a");

    function myFunction() {
      if (document.body.scrollTop > 140 || document.documentElement.scrollTop > 140) {
        navbar1.style.backgroundColor = "#ffffff";
        navbar1.style.padding = "10px";
        
        logo.style.display = 'inline-block';
        logo.style.backgroundImage = "url(logo-onroute-black.svg)";
        logo.style.backgroundRepeat  = "no-repeat";
        logo.style.backgroundSize = "contain";
        logo.style.backgroundPosition = "center";

      }
      else {
        navbar1.style.backgroundColor = "";
        navbar1.style.padding = "20px";

        logo.style.display = 'inline-block';
        logo.style.background = "url(logo-onroute-white.svg)";
        logo.style.backgroundRepeat = "no-repeat";
        logo.style.backgroundSize = "contain";
        logo.style.backgroundPosition = "center";    

      }
    }


  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
    document.getElementById('menu_button').classList.toggle('collapsed');
  }

  openTrialModal() {
    const modal = this.modalService.open(TrialModalComponent, { centered: true, size: 'lg' });

  }

  ngAfterViewInit() {

    let sections: NodeListOf<Element> = document.querySelectorAll(".section");
    let self = this;

    Array.prototype.forEach.call(sections, function (e) {
      self.sections[e.id] = parseFloat(e.offsetTop);
    });

    this.scrollSpyService.getObservable('window').subscribe((e: any) => {
      let scrollPosition = document.documentElement.scrollTop;
      console.log("scroll position: ", scrollPosition)

      let width = window.innerWidth

      for (let menu in this.sections) {
        if ((this.sections[menu] - this.totalOffset) <= scrollPosition) {
          // Exclude sections that are not in the navbar links
          if (menu == "testi" || menu == "" || menu == "about") { }
          else {
            console.log('Width:', width);
            document.querySelector('a[data="id_' + menu + '"]').setAttribute('style', 'color:#FF0000!important;');
            if (scrollPosition > 140) {
              this.resetMenu();
              document.querySelector('a[data="id_' + menu + '"]').setAttribute('style', 'color:#FF0000!important');
            } else {
              this.clearMenu();
              document.querySelector('a[data="id_' + menu + '"]').setAttribute('style', 'color: #ffffff !important;');
   
            }


            if (width < 1025) {
              this.clearMenu();
            }

        
           

            document.getElementById('navbarCollapse').classList.remove('show');
          }
        }
      }
    });
  }

  clearMenu() {

      document.querySelector('a[data="id_home"]').setAttribute('style', 'color:#fff!important');
      document.querySelector('a[data="id_services"]').setAttribute('style', 'color:#fff!important');
      document.querySelector('a[data="id_features"]').setAttribute('style', 'color:#fff!important');
      document.querySelector('a[data="id_pricing"]').setAttribute('style', 'color:#fff!important');
      document.querySelector('a[data="id_contact"]').setAttribute('style', 'color:#fff!important');
    
  }

  resetMenu() {

    document.querySelector('a[data="id_home"]').setAttribute('style', 'color:#000!important');
    document.querySelector('a[data="id_services"]').setAttribute('style', 'color:#000!important');
    document.querySelector('a[data="id_features"]').setAttribute('style', 'color:#000!important');
    document.querySelector('a[data="id_pricing"]').setAttribute('style', 'color:#000!important');
    document.querySelector('a[data="id_contact"]').setAttribute('style', 'color:#000!important');
  
}
}



