import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollSpyService } from 'ngx-scrollspy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TosModalComponent } from 'src/app/components/tos-modal/tos-modal.component';
import { AboutUsModalComponent } from 'src/app/components/about-us-modal/about-us-modal.component';
import { FaqModalComponent } from 'src/app/components/faq-modal/faq-modal.component';
import { PrivacyPolicyModalComponent } from 'src/app/components/privacy-policy-modal/privacy-policy-modal.component';
import { EulaModalComponent } from 'src/app/components/eula-modal/eula-modal.component';
import { CookiePolicyModalComponent } from 'src/app/components/cookie-policy-modal/cookie-policy-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {

  switch = 1;
  cur_year = 0;
  sections = {};

  constructor(
    private router: Router,
    private scrollSpyService: ScrollSpyService,
    private modalService: NgbModal
  ) {
   }

  ngOnInit() {
    this.cur_year = new Date().getFullYear();
  }

  onChangeSwitch() {
    this.switch == 1 ?  this.switch = 0 : this.switch = 1;
  }
  onChangeColor(color:string)
  {
    document.getElementById("theme_id").className = "";
    document.getElementById('theme_id').classList.add('theme-red');
    document.getElementById('theme_id').classList.add('theme-'+color);
  }

  ngAfterViewInit() {

    let sections: NodeListOf<Element> = document.querySelectorAll(".section");
    let self = this;

    Array.prototype.forEach.call(sections, function (e) {
      self.sections[e.id] = parseFloat(e.offsetTop);
    });

    this.scrollSpyService.getObservable('footer').subscribe((e: any) => {
      let scrollPosition = document.documentElement.scrollTop;

      for (let menu in this.sections) {
        if ((this.sections[menu] - 35) <= scrollPosition) {
          if (menu == "testi" || menu == "") { }
          else {
            this.clearMenu();
            if (this.router.url == "/index4") {
              document.querySelector('a[data="id_' + menu + '"]').setAttribute('style', 'color:#f85f89!important');
            }
            else {
              document.querySelector('a[data="id_' + menu + '"]').setAttribute('style', 'color:#FF0000!important');
            }
            document.getElementById('navbarCollapse').classList.remove('show');
          }
        }
      }
    });
  }

  openTosModal() {
    const modal = this.modalService.open(TosModalComponent, { centered: true, size: 'lg' });
  }

  openPrivacyPolicyModal() {
    const modal = this.modalService.open(PrivacyPolicyModalComponent, { centered: true, size: 'lg' });
  }

  openAboutUsModal() {
    const modal = this.modalService.open(AboutUsModalComponent, { centered: true, size: 'lg' });
  }

  openCookiePolicyModal() {
    const modal = this.modalService.open(CookiePolicyModalComponent, { centered: true, size: 'lg' });
  }

  openEulaModal() {
    const modal = this.modalService.open(EulaModalComponent, { centered: true, size: 'lg' });
  }

  openFAQModal() {
    const modal = this.modalService.open(FaqModalComponent, { centered: true, size: 'lg' });
  }

  clearMenu() {
    if (this.router.url == "/index4") {
      document.querySelector('a[data="id_home"]').setAttribute('style', 'color:#393f4f !important');
      document.querySelector('a[data="id_services"]').setAttribute('style', 'color:#393f4f!important');
      document.querySelector('a[data="id_features"]').setAttribute('style', 'color:#393f4f!important');
      document.querySelector('a[data="id_pricing"]').setAttribute('style', 'color:#393f4f!important');
      document.querySelector('a[data="id_contact"]').setAttribute('style', 'color:#393f4f!important');
    }
    else {
      document.querySelector('a[data="id_home"]').setAttribute('style', 'color:#000!important');
      document.querySelector('a[data="id_services"]').setAttribute('style', 'color:#000!important');
      document.querySelector('a[data="id_features"]').setAttribute('style', 'color:#000!important');
      document.querySelector('a[data="id_pricing"]').setAttribute('style', 'color:#000!important');
      document.querySelector('a[data="id_contact"]').setAttribute('style', 'color:#000!important');
    }
  }
}
