import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/@core/auth/auth.service';
import jwtDecode from 'jwt-decode';
import { Subscription, timer } from 'rxjs';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

    timerRenew: Subscription;

  constructor(private router: Router, private authService: AuthService) {
      this.scheduleRenew();
   }


  ngOnInit() {
    document.getElementById('theme_id').classList.add('theme-red');
    document.getElementById('theme_id').classList.add('theme-dark');
  }

    scheduleRenew() {
        const token = jwtDecode(this.authService.getToken());
        const d = new Date(token.exp * 1000);
        d.setMinutes(d.getMinutes() - 30);
        // disable renew when exp is not valid
        if (d <= new Date()) {
            return;
        }
        this.timerRenew = timer(d).subscribe(() => {
            this.authService.getAnonymousToken().subscribe(() => {
                this.timerRenew.unsubscribe();
                this.scheduleRenew();
            });
        });
    }

}
