
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class BaseGuardService implements CanActivate {

  constructor(
    private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // change in case introduce login
    return Observable.create((observer: Observer<boolean>) => {
      this.authService.getAnonymousToken().subscribe(() => {
        observer.next(true);
        observer.complete();
      }, () => {
        observer.next(false);
      });
    });
  }

}
