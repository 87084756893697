import { AuthService } from './auth.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token.interceptor';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule } from '@angular/forms';

const SERVICES = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    AuthService,
    CookieService
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [],
    providers: [
        ...SERVICES,
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthModule,
            providers: [
                ...SERVICES,
            ],
        } as ModuleWithProviders;
    }
}
