import { SharedDataService } from 'orv-ui-base-lib';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {

    token: string;
    tokenCookie = 'ORVPublicwebToken';

    constructor(
        protected http: HttpClient,
        private cookieService: CookieService,
        private sharedDataService: SharedDataService) {

        this.sharedDataService.currentToken.subscribe((token: string) => { this.token = token; });
    }

    getAnonymousToken() {
        return this.http.get('/ws/jwt/token', {}).pipe(map((token: any) => {
            this.cookieService.set(this.tokenCookie, token.jwt);
        }));
    }

    hasCookie() {
        return this.cookieService.get(this.tokenCookie);
    }

    getToken() {
        return this.cookieService.get(this.tokenCookie);
    }

}

