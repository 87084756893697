import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { BaseGuardService } from './@core/auth/base-guard/base-guard.service';
import { CookiePolicyModalComponent } from './components/cookie-policy-modal/cookie-policy-modal.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: LayoutComponent, loadChildren: './pages/pages.module#PagesModule' },
      { path: 'signup', component: DashboardComponent, loadChildren: './signup/signup.module#SignupModule' },
    ],
    canActivate: [ BaseGuardService ],
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
