import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './@core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { AuthModule } from './@core/auth/auth.module';
import { CustomFormsModule } from 'ngx-custom-validators';
import { BaseGuardService } from './@core/auth/base-guard/base-guard.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    AuthModule,
    CustomFormsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({}),
    CoreModule.forRoot(),
    BrowserAnimationsModule,  // Add this only in the root module
  ],
  providers: [
    BaseGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
